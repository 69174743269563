<template>
  <div class="quickRegister-information">
    <div class="head_nav">
      <van-nav-bar title="认证信息"
                   class="head_nar">
      </van-nav-bar>
    </div>
    <van-row class="boert">
      <van-col span="5"
               class="lexw">姓名</van-col>
      <van-col span="18"
               class="text-border">
        <van-field v-model="RealName"
                   rows="1"
                   maxlength="5"
                   placeholder="请填写姓名" />
      </van-col>
    </van-row>
    <van-row class="boert">
      <van-col span="5"
               class="lexw">手机号</van-col>
      <van-col span="18"
               class="text-border">
        <van-field v-model="OriginalPhone"
                   rows="1"
                   autosize
                   :formatter="formatter"
                   maxlength="11"
                   placeholder="请填写手机号" />
      </van-col>
    </van-row>
    <licplatenber @getPlateLicense="getPlateLicense"
                  v-if="verification" />
    <licplatenber @getPlateLicense="getPlateLicense"
                  v-if="verificationLicensePlate" />
    <van-row class="boert">
      <van-col span="5"
               class="lexw">验证码</van-col>
      <van-col span="11"
               class="text-border">
        <van-field v-model="VerificationCode"
                   maxlength="6"
                   autosize
                   placeholder="请填写验证码" />
      </van-col>
      <van-col span="8">
        <van-button class="pohert"
                    :color="color"
                    type="success"
                    size="normal"
                    @click="getCode">{{codeMsg}}</van-button>
      </van-col>
    </van-row>
    <van-button class="spoty"
                block
                style="background-color: #1a8cfe; color: #fff"
                @click="SubmitPhome"
                type="primary">确定提交</van-button>
    <van-button class="spoHo"
                block
                style="background-color: #ff976a; color: #fff"
                @click="SubmitCancelDriver"
                type="warning"
                v-if="warningCancellation">申请注销</van-button>
  </div>
</template>
<script>
import { onMounted, reactive, toRefs, provide, ref } from "vue";
import { GetUrlWxOauth2 } from "@/serviceApi/User";
import { QuickRegisterVerifyCode } from "@/serviceApi/Driver"
import { Toast } from "vant";
import { getUrlParam } from "@/commonContent/js/comm";
import { setCookietime, getCookie, delCookie } from "@/commonContent/js/Cookie";
import { Dialog } from 'vant';
import licplatenber from "@/components/eup-OrderList/licplatenber.vue";
import { DriverQuickRegister, CheckRegister } from "@/serviceApi/Driver"
import { useRouter } from "vue-router";
export default {
  components: {
    licplatenber,
  },
  setup () {
    const router = useRouter();
    //响应式父子传值
    const plateNumber = ref("");
    const isSubmit = ref("");
    provide("plateNumber", plateNumber);
    provide("isSubmit", isSubmit);
    const state = reactive({
      OriginalPhone: null,
      PlateLicense: null,
      VerificationCode: null,
      RealName: null,
      // 是否禁用按钮
      codeDisabled: false,
      // 倒计时秒数
      countdown: 60,
      // 按钮上的文字
      codeMsg: "获取",
      // 定时器
      timer: null,
      url: "",
      color: "",
      verificationLicensePlate: false,
      verification: false,
      warningCancellation: false,

    });
    onMounted(() => {
      let openid = getUrlParam("openid");
      if (navigator.userAgent.toLowerCase().indexOf("micromessenger") >= 0) {
        if (navigator.userAgent.toLowerCase().indexOf("micromessenger") >= 0 && openid == "") {
          let redirectUrl = window.location.href;
          let parent = {
            redirectUrl: redirectUrl
          }
          GetUrlWxOauth2(parent).then((res) => {
            if (res.code == 1) {
              state.url = res.data.url;
              window.location.href = state.url;
            } else {
              Toast.fail(res.msg);
            }
          });
        }
        if (navigator.userAgent.toLowerCase().indexOf("micromessenger") >= 0 && openid != "") {
          let RealName = getUrlParam("RealName");
          let Phone = getUrlParam("Phone");
          let LicensePlate = getUrlParam("LicensePlate");
          state.OriginalPhone = Phone;
          if (LicensePlate) {
            state.verification = true;
            isSubmit.value = "submitShow";
          } else {
            state.verificationLicensePlate = true;
          }
          plateNumber.value = LicensePlate;
          state.RealName = RealName;
          let params = {
            licensePlate: LicensePlate,
          }
          CheckRegister(params).then((res) => {
            if (res.code == 1 && res.data.register) {
              state.warningCancellation = true;
              Toast.fail("车辆已被注册。非本人注册？请申请注销。");
            } else {

            }
          });

        }
      } else {
        state.verificationLicensePlate = true;
      }
    });
    const getCode = () => {
      if (state.OriginalPhone == "") {
        return Toast.fail("手机号不能为空");
      }
      if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(state.OriginalPhone))) {
        return Toast.fail("手机号格式不正确")
      }
      if (state.PlateLicense == "") {
        return Toast.fail("车牌号不能为空");
      };
      if (state.PlateLicense.length < 7) {
        return Toast.fail("车牌号不完整");
      };
      if (state.PlateLicense.length > 8) {
        return Toast.fail("车牌号不正确");
      };
      if (!state.timer) {
        state.timer = setInterval(() => {
          if (state.countdown > 0 && state.countdown <= 60) {
            state.countdown--;
            state.color = "#07c161b6"
            if (state.countdown != 0) {
              state.codeMsg = state.countdown
            }
            else {
              clearInterval(state.timer);
              state.codeMsg = "重发"
              state.countdown = 60;
              state.color = "#07c160"
              state.timer = null;
              state.codeDisabled = false
            }
          }
        }, 1000)

        let params = {
          "phone": state.OriginalPhone,
          "licensePlate": state.PlateLicense
        }
        QuickRegisterVerifyCode(params).then((res) => {
          if (res.code == 1) {
            setCookietime("verifyCodeKey", res.data.data.key);
          } else {
            Toast.fail(res.msg)
            clearInterval(state.timer);
            state.codeMsg = "获取"
            state.countdown = 60;
            state.color = "#07c160"
            state.timer = null;
            state.codeDisabled = false
          }
        })
      }
    };
    const formatter = (value) => {
      // 过滤输入的非数字
      return value.replace(/[^\d]/g, "");
    }
    const SubmitPhome = () => {

      if (state.RealName == "") {
        return Toast.fail("姓名不能为空");
      };
      if (state.OriginalPhone == "") {
        return Toast.fail("手机号不能为空");
      };
      if (state.PlateLicense == "") {
        return Toast.fail("车牌号不能为空");
      };
      if (state.PlateLicense.length < 7) {
        return Toast.fail("车牌号不完整");
      };
      if (state.PlateLicense.length > 8) {
        return Toast.fail("请输入正确车牌号");
      }
      if (state.VerificationCode == "") {
        return Toast.fail("验证码不能为空");
      };
      let openid = getUrlParam("openid");
      let carId = getUrlParam("CarId");
      let parent = {
        "realName": state.RealName,
        "phone": state.OriginalPhone,
        "licensePlate": state.PlateLicense,
        "carId": carId,
        "openId": openid,
        "code": state.VerificationCode,
        "key": getCookie("verifyCodeKey")
      };
      Dialog.confirm({
        title: '认证信息',
        message: '确认信息无误'
        ,
      })
        .then(() => {
          DriverQuickRegister(parent).then((res) => {
            if (res.code == 1) {
              Toast.success(res.msg);
              delCookie("verifyCodeKey");
              let url = "" + process.env.VUE_APP_API_UA + "/account/login?returnurl=" + encodeURIComponent(window.location.origin);
              window.location.href = url;
              return
            } else {
              return Toast.fail(res.msg)
            }
          });
        }).catch(() => {

        })
    };
    const getPlateLicense = (val) => {
      state.PlateLicense = val;
    };
    const SubmitCancelDriver = () => {
      let LicensePlate = getUrlParam("LicensePlate");
      router.push({ name: "CancelDriver", query: { LicensePlate: LicensePlate } });
    };
    return {
      ...toRefs(state), getCode, SubmitPhome, formatter, getPlateLicense, plateNumber, SubmitCancelDriver
    }
  },
}
</script>
<style>
</style>